<template>
<h1>{{ wellcome }}</h1>
<div class="container">
<div class="row">
 <ul class="nav justify-content-center lang-links">
  <li class="nav-item">
    <a class="nav-link" aria-current="page" @click="turkish">Türkçe</a>
  </li>
  
  <li class="nav-item">
    <a class="nav-link" @click="macedon">Macedonian</a>
  </li>
  <li class="nav-item">
    <a class="nav-link " @click="albanian" >Albanian</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" @click="english" >English</a>
  </li>
</ul>
</div>

<div class="row">
  <div class="col-md-12 col-lg-6 col-xl-6">
   <a href="https://www.faydahome.com/"> <img alt="Vue logo" class="img-fluid" src="./assets/logo.png"> </a>
  </div> 
  <div class="col-md-12 col-lg-6 col-xl-6">
   <a href="https://www.izmiroliveoil.com/"> <img alt="Vue logo" class="img-fluid" src="https://www.izmiroliveoil.com/image/catalog/izmirlogo.jpg"> </a>
  </div> 
</div>
<section class="section-products">
		<div class="container">
				<div class="row justify-content-center text-center">
						<div class="col-md-8 col-lg-6">
								<div class="header">
										<h3>Featured Product</h3>
										<h2>İzmir Olive Oil</h2>
								</div>
						</div>
				</div>
				<div class="row">
						<!-- Single Product -->
						<div class="col-md-6 col-lg-4 col-xl-3">
								<div id="product-1" class="single-product">
										<div class="part-1">
												<ul>
														<li><a href="#"><i class="fas fa-shopping-cart"></i></a></li>
														<li><a href="#"><i class="fas fa-heart"></i></a></li>
														<li><a href="#"><i class="fas fa-plus"></i></a></li>
														<li><a href="#"><i class="fas fa-expand"></i></a></li>
												</ul>
										</div>
										<div class="part-2">
												<h3 class="product-title">İzmir Olive Oil 500ml Glass Bottle</h3>
												
										</div>
								</div>
						</div>
						<!-- Single Product -->
						<div class="col-md-6 col-lg-4 col-xl-3">
								<div id="product-2" class="single-product">
										<div class="part-1">
												
												<ul>
														<li><a href="#"><i class="fas fa-shopping-cart"></i></a></li>
														<li><a href="#"><i class="fas fa-heart"></i></a></li>
														<li><a href="#"><i class="fas fa-plus"></i></a></li>
														<li><a href="#"><i class="fas fa-expand"></i></a></li>
												</ul>
										</div>
										<div class="part-2">
												<h3 class="product-title">İzmir Olive Oil 1 LT Pet Bottle</h3>
											
										</div>
								</div>
						</div>
						<!-- Single Product -->
						<div class="col-md-6 col-lg-4 col-xl-3">
								<div id="product-3" class="single-product">
										<div class="part-1">
												<ul>
														<li><a href="#"><i class="fas fa-shopping-cart"></i></a></li>
														<li><a href="#"><i class="fas fa-heart"></i></a></li>
														<li><a href="#"><i class="fas fa-plus"></i></a></li>
														<li><a href="#"><i class="fas fa-expand"></i></a></li>
												</ul>
										</div>
										<div class="part-2">
												<h3 class="product-title">İzmir Olive Oil 5 LT Tin </h3>
												
										</div>
								</div>
						</div>
						<!-- Single Product -->
						<div class="col-md-6 col-lg-4 col-xl-3">
								<div id="product-4" class="single-product">
										<div class="part-1">
												<span class="new">new</span>
												<ul>
														<li><a href="#"><i class="fas fa-shopping-cart"></i></a></li>
														<li><a href="#"><i class="fas fa-heart"></i></a></li>
														<li><a href="#"><i class="fas fa-plus"></i></a></li>
														<li><a href="#"><i class="fas fa-expand"></i></a></li>
												</ul>
										</div>
										<div class="part-2">
												<h3 class="product-title">Fabulous Triple Campaign</h3>
												
										</div>
								</div>
						</div>
						
						
				</div>
		</div>
</section>
<contact/>
</div>


  <ContactCompany />
  
</template>

<script>

import ContactCompany from './components/ContactCompany.vue'

export default {
  name: 'App',
  components: {
    
	ContactCompany,
  },
  data() {
    return {
      count:0,
      wellcome : " Dobredojdovte vo Grupacijata Bratko ",	  
      products : [
        { id:1, name: 'Sucuklu Tost' , price : 12},
        { id:2, name: 'Kaşarlı Tost' , price : 12},
        { id:3, name: 'Karışık Tost' , price : 16},
        { id:4, name: 'Ayvalık Tost' , price : 19},
      ],
    }
  },	

   methods: {
	titleDegis() {
		document.title = this.wellcome
	},
    english() {
      this.wellcome = "Welcome to Bratko Group"
    },

    turkish() {
      this.wellcome = "Bratko Group Hoş Geldiniz Der "
    },

	macedon() {
      this.wellcome = "Dobredojdovte vo Grupacijata Bratko"
    },
	
	albanian() {
      this.wellcome = "Mirësevini në Bratko Group"
    },


  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.lang-links a  { color :#E7ECEE; font-size:1.7rem; }
.lang-links    {background-color:#113662; }
.lang-links a:hover   { color : #A5AAA3; }
.brand-title {color:#E7ECEE;font-size:2rem;border:0; text-decoration: none;}
.brand-title:hover {color:#705E78;}



.section-products {
    padding: 80px 0 54px;
}

.section-products .header {
    margin-bottom: 50px;
}

.section-products .header h3 {
    font-size: 1rem;
    color: #fe302f;
    font-weight: 500;
}

.section-products .header h2 {
    font-size: 2.2rem;
    font-weight: 400;
    color: #444444; 
}

.section-products .single-product {
    margin-bottom: 26px;
}

.section-products .single-product .part-1 {
    position: relative;
    height: 290px;
    max-height: 290px;
    margin-bottom: 20px;
    overflow: hidden;
}

.section-products .single-product .part-1::before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: all 0.3s;
}

.section-products .single-product:hover .part-1::before {
		transform: scale(1.2,1.2) rotate(5deg);
}

.section-products #product-1 .part-1::before {
    background: url("https://www.izmiroliveoil.com/image/cache/catalog/1ltizmiroliveoilvitrin-640x640.JPG") no-repeat center;
    background-size: cover;
		transition: all 0.3s;
}

.section-products #product-2 .part-1::before {
    background: url("https://www.izmiroliveoil.com/image/cache/catalog/REX_9198-640x640.JPG") no-repeat center;
    background-size: cover;
}

.section-products #product-3 .part-1::before {
    background: url("https://www.izmiroliveoil.com/image/cache/catalog/Urunler/5l/5ltizmiroliveoil-1-640x640.JPG") no-repeat center;
    background-size: cover;
}

.section-products #product-4 .part-1::before {
    background: url("https://www.izmiroliveoil.com/image/cache/catalog/3%20l%C3%BC%20set%20-1-640x640.JPG") no-repeat center;
    background-size: cover;
}

.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
    position: absolute;
    top: 15px;
    left: 20px;
    color: #ffffff;
    background-color: #fe302f;
    padding: 2px 8px;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.section-products .single-product .part-1 .new {
    left: 0;
    background-color: #444444;
}

.section-products .single-product .part-1 ul {
    position: absolute;
    bottom: -41px;
    left: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
    bottom: 30px;
    opacity: 1;
}

.section-products .single-product .part-1 ul li {
    display: inline-block;
    margin-right: 4px;
}

.section-products .single-product .part-1 ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    color: #444444;
    text-align: center;
    box-shadow: 0 2px 20px rgb(50 50 50 / 10%);
    transition: color 0.2s;
}

.section-products .single-product .part-1 ul li a:hover {
    color: #fe302f;
}

.section-products .single-product .part-2 .product-title {
    font-size: 1rem;
}

.section-products .single-product .part-2 h4 {
    display: inline-block;
    font-size: 1rem;
}

.section-products .single-product .part-2 .product-old-price {
    position: relative;
    padding: 0 7px;
    margin-right: 2px;
    opacity: 0.6;
}

.section-products .single-product .part-2 .product-old-price::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #444444;
    transform: translateY(-50%);
}
</style>
